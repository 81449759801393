.card-register {
  overflow: hidden;
  text-align: left;
  z-index: 2;

  .card-header img {
    position: absolute;
    margin-left: -50px;
    margin-top: -150px;
    border-radius: 20%;
    width: 350px;
    }

    .header {
        margin-left: 20px;
        margin-right: 20px;
        padding: 30px 0;
    }

    .text-divider {
        margin-top: 30px;
        margin-bottom: 0px;
        text-align: center;
    }

    .content {
        padding: 0px 30px;
    }

    .form-check {
        margin-top: 20px;
        margin-left: 20px;

        label:not(.form-check-label){
          padding-left: 38px;
        }
    }

    .card-header {
      padding: 0 0 50px;
      overflow: hidden;

      .card-title {
        position: relative;
        font-size: 4em;
        font-weight: 900;
        color: $black;
        text-transform: lowercase;
        margin-left: -5px;
        z-index: 1;
      }
    }

    .card-img {
      position: absolute;
      left: 0;
      top: -85px;
      width: 70%;
    }

    .social-line {
        margin-top: 20px;
        text-align: center;

        .btn.btn-icon ,
        .btn.btn-icon .btn-icon {
            margin-left: 5px;
            margin-right: 5px;
            box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2);
        }
    }

    .card-footer {
        /*margin-bottom: 10px;
        margin-top: 24px;*/
    }
}

@include media-breakpoint-down(md){
  .card.card-register .card-img {
    margin-left: -45px;
  }
}

@include media-breakpoint-down(xs){
  .card.card-register {
    margin-top: 50px;
  }
}
